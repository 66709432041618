<template>
  <div style="padding:20px" v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中"
  element-loading-background="rgba(255, 255, 255, 0.5)">
    <el-select clearable style="width:150px;margin-right:10px" v-model="pageInfo.marketId" placeholder="地区" @change="find">
        <el-option
        v-for="item in areaList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
        clearable
        >
        </el-option>
    </el-select>
    <el-select clearable style="width:150px;margin-right:10px" v-model="pageInfo.state" placeholder="状态" @change="find">
        <el-option
        label="已授权"
        value="1"
        >
        </el-option>
        <el-option
        label="未授权"
        value="0"
        >
        </el-option>
    </el-select>
    <el-input clearable style="width:150px;margin-right:10px" v-model="pageInfo.keyword" placeholder="店铺名称/卖家ID" @keyup.enter.native = "find"></el-input>
    <el-button @click="find" type="primary" icon="el-icon-search">查询</el-button>
    <el-button type="primary" icon="el-icon-plus" @click="addStoreOpen('添加店铺')">添加店铺</el-button>

    <el-table
    border
    :data="tableDate"
    cell-style="text-align:center"
    header-cell-style="text-align:center;background:#EFEFEF"
    style="width: 100%;margin-top:20px">
        <el-table-column
            prop="marketName"
            width="width"
            show-overflow-tooltip>
            <template slot="header">
                <span>市场</span>
                <!-- <span class="el-icon-question"></span> -->
            </template>
        </el-table-column>
        <el-table-column
            prop="prop"
            label="店铺名称"
            width="300">
            <template slot="header">
                <span>店铺名称</span>
                <!-- <span class="el-icon-question"></span> -->
            </template>
            <template slot-scope="scope">
                <span>{{scope.row.shopName}}</span><el-link @click="changeShopName(scope.row.id,scope.row.shopName)" style="margin-left:5px" type="primary" class="el-icon-edit"></el-link>
            </template>
        </el-table-column>
        <el-table-column
            prop="tips"
            label="店铺备注"
            width="width">
            <template slot="header">
                <span>店铺备注</span>
                <!-- <span class="el-icon-question"></span> -->
            </template>
        </el-table-column>
        <el-table-column
            prop="amazonSellerId"
            label="卖家ID"
            width="140px">
            <template slot="header">
                <span>卖家ID</span>
                <!-- <span class="el-icon-question"></span> -->
            </template>
            <template slot-scope="scope">
                <el-tooltip :content="scope.row.amazonSellerId||'无'" placement="top">
                    <el-link type='primary'>查看</el-link>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column
            prop="prop"
            label="授权令牌"
            width="140px">
            <template slot="header">
                <span>授权令牌</span>
                <!-- <span class="el-icon-question"></span> -->
            </template>
            <template slot-scope="scope">
                <el-tooltip :content="scope.row.amzSpLwa||'无'" placement="top">
                    <el-link type='primary'>查看</el-link>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column
            prop="prop"
            label="邮箱配置"
            width="140px">
            <template slot="header">
                <span>邮箱配置</span>
                <!-- <span class="el-icon-question"></span> -->
            </template>
            <template slot-scope="scope">
                <el-tooltip :content="scope.row.shopAuthorizeStateName=='已授权'?'点击查看更多配置':'店铺未授权'" placement="top">
                    <el-link type='primary' :disabled="scope.row.shopAuthorizeStateName=='已授权'?false:true" @click="mailMore(scope.row.id,scope.row.shopName)">更多</el-link>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column
            prop="shopAuthorizeStateName"
            label="店铺授权状态"
            width="width">
            <template slot="header">
                <span>店铺授权状态</span>
                <!-- <span class="el-icon-question"></span> -->
            </template>
            <template slot-scope="scope">
                <el-link type="success" v-if="scope.row.shopAuthorizeStateName=='已授权'">{{scope.row.shopAuthorizeStateName}}</el-link>
                <el-link @click="nowAccredit('店铺授权',scope.row.id)" type="danger" v-if="scope.row.shopAuthorizeStateName=='立即授权'">{{scope.row.shopAuthorizeStateName}}</el-link>
            </template>
        </el-table-column>
        <el-table-column
            prop="prop"
            label="广告授权状态"
            width="width">
            <template slot="header">
                <span>广告授权状态</span>
                <!-- <span class="el-icon-question"></span> -->
            </template>
            <template slot-scope="scope">
                <el-link type="success" v-if="scope.row.advertAuthorizeStateName=='已授权'">{{scope.row.advertAuthorizeStateName}}</el-link>
                <el-link @click="nowSlogan(scope.row.id,scope.row.shopName)" type="danger" v-if="scope.row.advertAuthorizeStateName=='立即授权'">{{scope.row.advertAuthorizeStateName}}</el-link>
            </template>
        </el-table-column>
        <el-table-column
            prop="prop"
            label="更新时间/到期时间"
            width="200px">
            <template slot="header">
                <span>更新时间/到期时间</span>
                <!-- <span class="el-icon-question"></span> -->
            </template>
            <template slot-scope="scope">
                <p style="color:#2BDC70;font-size:14px;line-height:1">{{scope.row.updateTime}}</p>
                <p style="font-size:14px;line-height:1">{{scope.row.expirationTime}}</p>
            </template>
        </el-table-column>
        <el-table-column
            prop="prop"
            label="操作"
            width="200">
            <template slot="header">
                <span>操作</span>
                <!-- <span class="el-icon-question"></span> -->
            </template>
            <template slot-scope="scope">
                <el-button style="font-size:14px" type="text" icon="el-icon-s-tools" @click="setShowInit(scope.row.id,scope.row.marketName,scope.row.shopName)">展示设置</el-button>
                <el-button @click="updateAccredit('更新授权',scope.row.id)" style="font-size:14px" type="text" icon="el-icon-refresh">更新授权</el-button>
            </template>
        </el-table-column>
    </el-table>
    <div style="text-align:right;padding:15px">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
    <el-dialog
    :title="title"
    :visible.sync="addStoredialog"
    min-width="45%"
    :before-close="Close"
    class="addStoreBox"
    >
        <template slot="title">
            <span>{{title}}</span>
            <el-tooltip placement="bottom" v-if="title=='添加店铺'||'店铺授权'">
                <div slot="content"><el-link type='success'>了解更多信息>>(后期补充)</el-link></div>
                <span class="iconfont icon-wenhao" style="font-size:18px;margin-left:6px"></span>
            </el-tooltip>
        </template>
        <span v-if="this.title!='更新授权'" style="margin-bottom:20px;display:block">授权成功后，每日销售额、利润等指标一目了然，还可以添加产品监控实时跟踪差评和产品变化</span>
        <el-form 
        :model="addForm" 
        :rules="addFormRules" 
        ref="addForm" 
        label-width="110px"
        style="margin:auto;width:700px"
        v-loading="dialogLoading">
            <el-form-item label="选择授权平台" prop="platform">
                <el-radio :disabled="title=='更新授权'?true:false" @change="changePlatform" v-model="platform" label="1">AMmazon</el-radio>
                <el-radio :disabled="title=='更新授权'?true:false" @change="changePlatform" v-model="platform" label="2">Rakuten</el-radio>
                <el-radio :disabled="title=='更新授权'?true:false" @change="changePlatform" v-model="platform" label="304">Shopify</el-radio>
            </el-form-item>
            <el-form-item v-if="platform==1" label="选择授权市场" prop="marketId">
                <el-radio-group v-model="addForm.marketId">
                    <el-radio 
                    @change="deletEles"
                    :label="item.id"
                    v-for="item in marketList"
                    :key="item.id">
                    {{item.name}}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item v-if="platform!=2" label="选择运营站点" prop="marketPlaceIds">
                <div class="site">
                    <span>北美</span>
                    <el-checkbox-group v-model="addForm.marketPlaceIds">
                        <el-checkbox 
                        :disabled='!disabled1||platform==304?false:true'
                        :label="item.id" 
                        @change="getMarketplaceIds(item.id)"
                        name="type"
                        v-for="item in camas"
                        :key="item.id">
                        {{item.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div class="site">
                    <span>欧洲</span>
                    <el-checkbox-group v-model="addForm.marketPlaceIds">
                        <el-checkbox 
                        :disabled='!disabled2||platform==304?false:true'
                        :label="item.id" 
                        @change="getMarketplaceIds(item.id)"
                        name="type"
                        v-for="item in europee"
                        :key="item.id">
                        {{item.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div class="site">
                    <span>远东</span>
                    <el-checkbox-group v-model="addForm.marketPlaceIds">
                        <el-checkbox 
                        :disabled='!disabled3||platform==304?false:true'
                        :label="item.id" 
                        @change="getMarketplaceIds(item.id)"
                        name="type"
                        v-for="item in East"
                        :key="item.id">
                        {{item.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-form-item>
            <el-form-item v-if="platform==2" label="选择运营站点" prop="marketPlaceIds">
                <el-checkbox v-model="addForm.marketPlaceIds" label="16">日本</el-checkbox>
            </el-form-item>
            <el-form-item v-if="title=='添加店铺'" label="店铺名称" prop="shopName">
                <el-input v-model="addForm.shopName" style="width:60%" placeholder="请输入方便识别的店铺名，如Canqi"></el-input>
            </el-form-item>
            <!-- <el-form-item label="店铺备注" prop="tips">
                <el-input v-model="addForm.tips" style="width:60%" placeholder="请输入店铺备注"></el-input>
            </el-form-item> -->
            <el-form-item label="LicenseKey" v-if="platform==2" prop="key">
                <el-input v-model="addForm.key" placeholder="SLxxxxxxx" style="width:60%"></el-input>
            </el-form-item>
            <el-form-item label="SecretKey" v-if="platform==2" prop="value">
                <el-input v-model="addForm.value" placeholder="SPxxxxxxx" style="width:60%"></el-input>
            </el-form-item>
            <el-form-item label="店铺链接" v-if="platform==304" prop="shop">
                <el-input v-model="addForm.shop" placeholder="xxx.myshopify.com" style="width:60%"></el-input>
            </el-form-item>
            <el-form-item prop="sure">
                <el-checkbox v-model="sure">确认当前电脑与IP是您授权店铺的常用环境</el-checkbox>
            </el-form-item>
            <el-form-item>
                <p>
                    <el-button :loading='buttonLoading' type="primary" plain  @click="goAccredit">{{title=='更新授权'?'确认更新':'去卖家平台授权'}}</el-button>
                    <el-button @click="Close">取 消</el-button>
                </p>
            </el-form-item>
        </el-form>
        
        <p v-if="this.title!='更新授权'" style="color:red">*温馨提示:点击前往授权，跳转到亚马逊后台，只需在授权确认页面：1、勾选，我了解；2、点击，确认授权；就能完成店铺授权</p>
        <!--授权中 -->
        
        
    </el-dialog>
    
    
    <el-dialog
    width="40%"
    :title='title'
    :visible.sync='setShow'>
        <el-table
        :data="showTable"
        v-loading="dialogLoading"
        cell-style="text-align:center"
        header-cell-style="text-align:center;background:#EFEFEF"
        style="width: 100%">
            <el-table-column
                prop="name"
                label="亚马逊站点"
                width="width">
                <template slot='header'>
                    <span>亚马逊站点</span>
                    <span class="el-icon-question"></span>
                </template>
            </el-table-column>
            <el-table-column
                prop="shopAuthorizeState"
                label="店铺授权"
                width="width">
                <template slot-scope="scope">
                    <span v-if="scope.row.shopAuthorizeState==1" style="color:#05E068;font-size:25px" class="el-icon-check"></span>
                    <span v-if="scope.row.shopAuthorizeState==0"  style="font-size:25px" class="el-icon-close"></span>
                </template>
            </el-table-column>
            <el-table-column
                prop="advertAuthorizeState"
                label="广告授权"
                width="width">
                <template slot-scope="scope">
                    <span v-if="scope.row.advertAuthorizeState==1" style="color:#05E068;font-size:25px" class="el-icon-check"></span>
                    <span v-if="scope.row.advertAuthorizeState==0"  style="font-size:25px" class="el-icon-close"></span>
                </template>
            </el-table-column>
            <el-table-column
                label="默认展示"
                width="width">
                <template slot='header'>
                    <span>默认展示</span>
                    <span class="el-icon-question"></span>
                </template>
                <template slot-scope="scope">
                    <el-switch
                    v-model="scope.row.defaultShow"
                    active-color="#13ce66"
                    inactive-color="#EAE5E5"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                    @change="defaultShow(scope.row.id)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                prop="status"
                label="启用禁用"
                width="width">
                <template slot-scope="scope">
                    <el-switch
                    v-model="scope.row.status"
                    active-color="#13ce66"
                    inactive-color="#EAE5E5"
                    :active-value="Number(1)"
                    :inactive-value="Number(0)"
                    @change="changeStatus(scope.row.id)">
                    </el-switch>
                </template>
            </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
            <el-button @click="setShow = false">取 消</el-button>
            <el-button type="primary" @click="setShow = false">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog
    :visible.sync="sloganWait"
    width="20%"
    :show-close="false">
        <div style="text-align:center">
            <p class="el-icon-loading" style="font-size:30px"></p>
            <h2 style="margin:10px 0">授权中</h2>
            <p style="margin-bottom:50px">正在进行广告授权，请稍等</p>
        </div>
    </el-dialog>
    <el-dialog
    title="广告授权"
    :visible.sync="sloganBox"
    width="25%"
    :before-close="dialogBeforeClose">
        <div>
            <p style="margin:-20px 0 15px 0">授权店铺名称：{{title}}</p>
            <div style="border:1px solid #c1c1c1;padding:5px">
                <p style="line-height:20px">1.即将进行广告授权，正常的广告授权很简单，一步搞定；</p>
                <p style="line-height:20px">2.如果授权失败，请重试时，先仔细阅读 亚马逊广告授权常见问题 ，了解清楚后再开始授权，或咨询我们的在线客服</p>
            </div>
            <div style="text-align:center;margin:10px">
                <el-checkbox v-model="sloganSure">确认当前电脑与IP是您授权店铺的常用环境</el-checkbox>
            </div>
        </div>
        <div style="text-align:center">
            <el-button @click="toFinishSlogan" type="primary" plain>去亚马逊卖家后台授权</el-button>
            <el-button @click="dialogBeforeClose">取 消</el-button>
        </div>
    </el-dialog>
    <el-dialog
    :visible.sync="waitSuccess"
    width="20%"
    :show-close="false">
        <div>
            <p style="color:#14c6a2;font-size:20px">店铺授权成功</p>  
            <p style="margin:10px 0 20px 0;line-height:19px">系统稍后进行订单、商品、库存等数据同步，预计需要2-3小时。如店铺数据量大，将花费更长的时间</p>  
            <p style="font-size:20px">继续授权广告</p>
            <p style="margin:10px 0 20px 0;line-height:19px">授权广告后，系统会同步您的广告数据。您可以更加直观的了解店铺的利润和商品利润情况，并可以在系统中操作、调整广告</p>
        </div>
        <div style="text-align:right">
            <el-button @click="Not">暂不授权</el-button>
            <el-button type="primary" @click="goFinishSlogan">去授权广告</el-button>
        </div>
    </el-dialog>
    <el-dialog
    :visible.sync="wait"
    width="20%"
    :show-close="false"
    >
        <div style="text-align:center">
            <p class="el-icon-loading" style="font-size:30px"></p>
            <h2 style="margin:10px 0">授权中</h2>
            <p style="margin-bottom:50px">正在进行店铺授权，请稍等</p>
        </div>
    </el-dialog>
    <el-dialog
    :visible.sync="sloganSuccess"
    width="20%"
    :show-close="false"
    >
        <div style="text-align:center">
            <span style="font-size:70px;color:#14c6a2" class="iconfont icon-duihao1"></span>
            <p style="text-align:center;font-size:22px;margin-top:15px">广告授权成功</p>
        </div>
        <div style="text-align:center;margin-top:20px">
            <el-button @click="sloganSuccess = false">关闭</el-button>
            <el-button type="primary" @click="goHome">去首页</el-button>
        </div>
    </el-dialog>
    <el-dialog
    :visible.sync="shopifySuccess"
    width="20%"
    :show-close="false"
    >
        <div style="text-align:center">
            <span style="font-size:70px;color:#14c6a2" class="iconfont icon-duihao1"></span>
            <p style="text-align:center;font-size:22px;margin-top:15px">shopify授权成功</p>
        </div>
        <div style="text-align:center;margin-top:20px">
            <el-button @click="again">关闭</el-button>
            <!-- <el-button type="primary" @click="sloganSuccess = false">去首页</el-button> -->
        </div>
    </el-dialog>
    <el-dialog
    :visible.sync="shopifyError"
    width="20%"
    :show-close="false"
    >
        <div style="text-align:center">
            <span style="font-size:70px;color:red" class="el-icon-circle-close"></span>
            <p style="text-align:center;font-size:22px;margin-top:15px">shopify授权失败</p>
        </div>
        <div style="text-align:center;margin-top:20px">
            <el-button @click="again">关闭</el-button>
            <!-- <el-button type="primary" @click="sloganSuccess = false">去首页</el-button> -->
        </div>
    </el-dialog>
    <el-dialog
    :visible.sync="sloganfail"
    width="20%"
    :show-close="false"
    >
        <div style="text-align:center">
            <span style="font-size:70px;color:red" class="el-icon-circle-close"></span>
            <p style="text-align:center;font-size:22px;margin-top:15px">{{Info}}</p>
        </div>
        <div style="text-align:center;margin-top:20px">
            <el-button type="primary" @click="again">返回重新授权</el-button>
            <el-button @click="again">关闭</el-button>
        </div>
    </el-dialog>
    <el-dialog
    :visible.sync="waitfail"
    width="20%"
    :show-close="false"
    >
        <div style="text-align:center">
            <span style="font-size:70px;color:red" class="iconfont iconbohuituihui"></span>
            <p style="text-align:center;font-size:22px;margin-top:15px">{{Info}}</p>
        </div>
        <div style="text-align:center;margin-top:20px">
            <el-button type="primary" @click="again">返回重新授权</el-button>
            <el-button @click="again">关闭</el-button>
        </div>
    </el-dialog>
    <el-dialog
        title="邮箱列表"
        :visible.sync="mailListBox"
        width="50%"
        :before-close="mailListBoxClose">
        <div v-loading='mailListLoading'>
            <el-select style="margin-right:10px" v-model="marketPlaceId">
                <el-option
                    v-for="item in marketPlaceList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                    @click.native="mailList">
                </el-option>
            </el-select>
            <el-button type="primary" @click="newMail('新增邮箱')">新增邮箱</el-button>
            <el-table
                header-cell-style="background:#F7F8FA"
                :data="mailList"
                cell-style="font-size:14px"
                style="width: 100%;margin-top:15px">
                <el-table-column
                    prop="mailNo"
                    label="邮箱"
                    width="400">
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="操作"
                    width="200">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editMail(scope.row.id,'编辑邮箱')">编辑</el-button>
                        <!-- <el-button type="text">权限管理</el-button> -->
                        <el-button type="text" @click="removeMail(scope.row.id)">解绑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog
                :title="mailTitle"
                append-to-body
                :visible.sync="addMail"
                width="35%"
                :before-close="addMailClose">
                <div style="padding-right:50px">
                    <el-button @click="option" type="text" style="margin:0 0 20px 50px">{{heightOption?'取消高级设置':'高级设置'}}</el-button>
                    <el-form ref="mailForm" :model="mailForm" :rules="mailFormRulse" label-width="100px">
                        <el-form-item label="账号">
                            <el-input disabled v-model="shopName"></el-input>
                        </el-form-item>
                        <el-form-item label="国家">
                            <el-select disabled style="width:100%" v-model="marketPlaceId">
                                <el-option
                                    v-for="item in marketPlaceList"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id"
                                    @click.native="mailList">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="邮箱账号" prop="mailNo">
                            <el-input  v-model="mailForm.mailNo"></el-input><br>
                            <el-button type='text' v-model="mailForm.mailNo">如何获取或设置亚马逊邮箱账号？</el-button>
                        </el-form-item>
                        <el-form-item label="密码/秘钥" prop="password">
                            <el-input type="password" placeholder="请输入密码" v-model="mailForm.password"></el-input>
                            <!-- <span>1、126,163邮箱需要额外验证,请<el-button type='text'>点此链接</el-button>进行验证</span><br> -->
                            <!-- <span style="margin-top:-6px"> 2、查看教程<el-button type='text'>《如何获取邮箱授权码》</el-button></span> -->
                        </el-form-item>
                        <el-form-item  v-if="heightOption" label="用户名" prop="nick">
                            <el-input v-model="mailForm.nick" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item v-if="heightOption" label="收信服务器" prop="imap">
                            <el-input v-model="mailForm.imap" placeholder="示例：imap.qq.com"></el-input>
                        </el-form-item>
                        <el-form-item v-if="heightOption" label="发信服务器" prop="smtp">
                            <el-input v-model="mailForm.smtp" placeholder="示例：smtp.qq.com"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div slot="footer">
                    <el-button @click="addMailClose">取 消</el-button>
                    <el-button type="primary" :loading='buttonLoading' @click="mailAccount">授权</el-button>
                </div>
            </el-dialog>
        </div>
        <!-- <div slot="footer">
            <el-button @click="mailListBoxClose">取 消</el-button>
            <el-button type="primary" @click="mailListBoxClose">确 定</el-button>
        </div> -->
    </el-dialog>
  </div>
</template>

<script>
            // this.$refs.addForm.clearValidate()
import 
{
    exitShopName, 
    listPage, 
    addStoreInit, 
    toAccredit,
    updateAuthorize, 
    updateInit, 
    searchInit, 
    getMarketPlace, 
    defaultShow, 
    changeStatus, 
    getAuthorizeMsg, 
    mailList,
    advertAuthorize, 
    mailPageInit,
    editMailInit,
    mailAccount,
    mailPassword,
    removeMail,
    ShopRakutenUpdate,
    shopRakutenAdd,
    shopify,
    addShopAndAuthorize,
    getSloganURL} from '@/api/basicManage/shopAuthorization.js'
export default {
    data(){
        return {
            platform:'1',
            mailTitle:'',
            dialogLoading:false,
            waitfail:false,
            sloganfail:false,
            Info:'',
            shopifyError:false,
            shopifySuccess:false,
            sloganSuccess:false,
            buttonLoading:false,
            dialogLoading:false,
            mailListLoading:false,
            addMailLoading:false,
            loading:false,
            mailListBox:false,
            addMail:false,
            mailList:[1,2],
            title:'',
            tableDate:[],
            showTable:[],
            addStoredialog:false,
            wait:false,
            waitSuccess:false,
            sure:false,
            id:'',
            addForm:{
                id:'',
                marketPlaceIds:[],
                marketId:'',
                shopName:'',
                tips:'',
                key:'',
                value:'',
                shop:'',
                userId:''
            },
            setShow:false,
            updateBox:false,
            pageInfo:{
                current:'',
                keyword:'',
                state:'',
                marketId:'',
                pageSize:''
            },
            total:'',
            areaList:[],
            marketList:[],//市场列表
            camas:[],//北美
            europee:[],//欧洲
            East:[],
            addFormRules:{
                marketId:[{required:true,message:'请选择授权市场',trigger:'change'}],
                shopName:[{required:true,message:'请填写店铺名称',trigger:'blur'}],
                shop:[{required:true,message:'请填写店铺链接',trigger:'blur'}],
                key:[{required:true,message:'请填写LicenseKey',trigger:'blur'}],
                value:[{required:true,message:'请填写店SecretKey',trigger:'blur'}],
            },
            sloganURL:'',
            sloganBox:false,
            sloganWait:false,
            sloganSure:false,
            marketPlaceList:[],
            marketPlaceId:'',
            shopId:'',
            shopName:'',
            mailForm:{
                imap:'',
                mailNo:'',
                marketPlaceId:'',
                nick:'',
                password:'',
                shopId:'',
                smtp:'',
            },
            heightOption:false,
            mailFormRulse:{
                mailNo:[{required:true,message:'请输入邮箱账号',trigger:'blur'},{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
                password:[{required:true,message:'请输入秘钥',trigger:'blur'}],
                imap:[{required:true,message:'请输入收信服务器',trigger:'blur'}],
                smtp:[{required:true,message:'请输入发信服务器',trigger:'blur'}],

            }
        };
    },
    created(){
        // getAuthorizeMsg().then(res=>{
        //     console.log(res.data.code);
        // })
        this.getListPage()
        this.getAreaList()
        if(JSON.stringify(this.$route.query)=='{}'){
            return
        }else {
            window.sessionStorage.setItem('accredit',JSON.stringify(this.$route.query))
        }
    },
    mounted(){
        const accredit = JSON.parse(window.sessionStorage.getItem('accredit'))||''
        if(!accredit){
            return
        }else {
            if(accredit.hmac){
                this.wait=true
                shopify(accredit).then(res=>{
                    console.log(res);
                    this.wait=false
                    if(res.data.code==200){
                        if(res.data.data){
                            window.sessionStorage.removeItem('accredit')
                            window.location.href=res.data.data
                            return
                        }
                        this.shopifySuccess=true
                        window.sessionStorage.removeItem('accredit')
                    }else{
                        this.shopifyError=true
                        window.sessionStorage.removeItem('accredit')
                    }
                }).catch((error)=>{
                    this.wait=false
                    this.$message.error('鉴权失败')
                })
                return
            }
            if(!accredit.code){
                this.wait=true
                getAuthorizeMsg(accredit).then(res=>{
                    // 在此获取生成广告URL的ID
                    console.log(res);
                    if(res.data.code==200){
                        this.wait=false 
                        window.sessionStorage.removeItem('accredit')
                        getSloganURL(res.data.data).then(re=>{
                            if(re.data.code==200){
                                // this.sloganURL=res.data
                                this.sloganURL=re.data.data
                            }
                            this.waitSuccess=true
                        })
                        //获取URL需要ID
                    }else {
                        // this.$message.info(res.data.message)
                        console.log(res.data.message);
                        this.Info=res.data.message
                        this.waitfail=true
                        window.sessionStorage.removeItem('accredit')
                        this.wait=false
                        return
                    }
                })
            }else {
                if(!accredit.shop){
                    //记得清除session存储
                    this.sloganWait=true
                    advertAuthorize(accredit).then(res=>{
                        if(res.data.code==200){
                            this.sloganWait=false
                            this.sloganSuccess=true
                            window.sessionStorage.removeItem('accredit')
                            // this.$router.push('/shopAuthorization')
                        }else{
                            // this.$message.info(res.data.message)
                            this.sloganfail=true
                            this.Info=res.data.message
                            window.sessionStorage.removeItem('accredit')
                            this.sloganWait=false
                            // this.$router.push('/shopAuthorization')
                        }
                    })
                }else {
                    this.wait=true
                    shopify(accredit).then(res=>{
                        this.wait=false
                        if(res.data.code==200){
                            this.shopifySuccess=true
                            window.sessionStorage.removeItem('accredit')
                        }else{
                            this.shopifyError=true
                            window.sessionStorage.removeItem('accredit')
                        }
                    })
                }
                
            }
        }
    },
    computed:{
        disabled1(){
            if(this.addForm.marketId==2||this.addForm.marketId==3||this.addForm.marketId==''){
                return true;
            }
        },
        disabled2(){
            if(this.addForm.marketId==1||this.addForm.marketId==3||this.addForm.marketId==''){
                return true;
            }
        },
        disabled3(){
            if(this.addForm.marketId==2||this.addForm.marketId==1||this.addForm.marketId==''){
                return true;
            }
        },
    },
    methods:{
        changePlatform(){
            this.$refs.addForm.resetFields()
            this.$refs.addForm.clearValidate()
        },
        getMarketplaceIds(id){
            if(this.platform==304) this.addForm.marketPlaceIds=[id]
        },
        removeMail(id){
            this.$confirm('邮箱解绑后邮箱数据将会被清空，确定解绑？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.mailListLoading=true
                removeMail(id,this.shopId,this.marketPlaceId).then(res=>{
                    this.mailListLoading=false
                    if(res.data.code==200){
                        this.$message({
                            type: 'success',
                            message: res.data.message
                        });
                        this.mailListPage()
                    }else {
                        this.$message({
                            type: 'error',
                            message: res.data.message
                        });
                    }
                }).catch(()=>{
                    this.mailListLoading=false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        editMail(id,info){
            this.id=id
            this.mailTitle=info
            this.mailListLoading=true
            editMailInit(id).then(res=>{
                console.log(res);
                this.mailListLoading=false
                if(res.data.code==200){
                    this.addMail=true
                    this.mailForm.mailNo=res.data.data.mailNo
                    this.mailForm.imap=res.data.data.imap
                    this.mailForm.nick=res.data.data.nick
                    this.mailForm.smtp=res.data.data.smtp
                    if(this.mailForm.imap) this.heightOption=true

                }else {
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.mailListLoading=false
            })
        },
        mailListBoxClose(){
            this.mailListBox=false
        },
        addMailClose(){
            this.$refs['mailForm'].resetFields() 
            this.addMail=false
            this.heightOption=false
            this.id=''
        },
        mailAccount(){
            this.$refs.mailForm.validate(valid=>{
                if(!valid) return;

                if(this.mailTitle=='新增邮箱'){
                    this.buttonLoading=true
                    mailAccount({...this.mailForm,shopId:this.shopId,marketPlaceId:this.marketPlaceId}).then(res=>{
                        this.buttonLoading=false
                        console.log(res);
                        if(res.data.code==200){
                            this.$message.success(res.data.message)
                            this.addMailClose()
                            this.mailListPage()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    })
                }else {
                    mailPassword({id:this.id,password:this.mailForm.password}).then(res=>{
                        this.buttonLoading=false
                        if(res.data.code==200){
                            this.$message.success(res.data.message)
                            this.addMailClose()
                            this.mailListPage()
                        }else {
                            this.$message.error(res.data.message)
                        }
                    }).catch(()=>{
                        this.buttonLoading=false
                    })
                }
                
            })
        },
        option(){
            this.heightOption=!this.heightOption
            this.mailForm.nick=''
            this.mailForm.imap=''
            this.mailForm.smtp=''
        },
        mailMore(id,name){
            this.shopName=name
            this.loading=true
            this.shopId=id
            mailPageInit(id).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.mailListBox=true
                    this.marketPlaceList=res.data.data.marketPlaceList
                    this.marketPlaceId=this.marketPlaceList[0].id||''
                    console.log(this.marketPlaceId);
                    if(this.marketPlaceId){
                        this.mailListPage()
                    }
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.loading=false
            })
        },
        newMail(info){
            this.addMail=true
            this.mailTitle=info
        },
        mailListPage(){
            this.mailListLoading=true
            mailList({shopId:this.shopId,marketPlaceId:this.marketPlaceId}).then(res=>{
                console.log(res);
                this.mailListLoading=false
                if(res.data.code!=200){
                    this.$message.error(res.data.message)
                }else{
                    this.mailList=res.data.data
                }
            })
        },
        again(){
            this.sloganfail=false
            this.shopifyError=false
            this.shopifySuccess=false
            this.waitfail=false
            this.getListPage()
            this.$router.push('/shopAuthorization')
            // window.location.href='https://www.cloudkinto.com/kinto/login'
        },
        goHome(){
            this.sloganSuccess=false
            this.$router.push('/home')
        },
        Not(){
            this.waitSuccess=false
           this.$router.push('/shopAuthorization')
        },
        nowSlogan(id,name){
            this.id=id
            this.title=name
            this.loading=true
            getSloganURL(id).then(res=>{
                if(res.data.code==200){
                    this.sloganURL=res.data.data
                    this.loading=false
                    this.sloganBox=true
                }else {
                    this.loading=false
                    this.$message.info(res.data.message)
                }
            })
        },
        goFinishSlogan(){
            // window.open(this.sloganURL)
            window.location.href=this.sloganURL
            this.waitSuccess=false
        },
        toFinishSlogan(){
            if(!this.sloganSure){
                this.$message.info('请确认当前电脑与IP是您授权店铺的常用环境')
                return
            }
            // window.open(this.sloganURL)
            window.location.href=this.sloganURL
            this.sloganSure=false
            this.sloganBox=false
            this.sloganURL=''
        },
        dialogBeforeClose(){
            this.sloganSure=false
            this.sloganBox=false
            this.sloganURL=''
        },
        getAreaList(){
            searchInit().then(res=>{
                this.areaList=res.data.data.marketList
            })
        },
        reset(){
            this.$refs.addForm.resetFields()
        },
        nowAccredit(title,id){
            this.dialogLoading=true
            this.title=title
            this.id=id
            this.addShopInit()
            this.FormInit(id)
            this.addStoredialog=true
        },
        updateAccredit(title,id){
            this.dialogLoading=true
            this.title=title
            this.id=id
            this.addShopInit()
            this.FormInit(id)
            this.addStoredialog=true
        },
        // 初始化
        FormInit(id){
            updateInit(id).then(res=>{
                console.log(res);
                const arr=res.data.data.amazonMarketInitTrees
                arr.forEach(item=>{
                    if(item.isSelected==1){
                        this.addForm.marketId=item.id
                        item.amazonMarketPlaceResList.forEach(i=>{
                            if(i.isSelected==1){
                                this.addForm.marketPlaceIds.push(i.id)
                            }
                        })
                    }
                })
                this.platform=String(res.data.data.platformId)
                this.addForm.tips=res.data.data.tips
            })
        },
        //去授权&&更新授权
        goAccredit(){
            this.$refs.addForm.validate(valid=>{
                if(!valid) return
                if(this.addForm.marketPlaceIds.length==0){
                    this.$message.info('请选择运营站点')
                    return
                }
                if(this.sure==false){
                    this.$message.info('请确认当前电脑与IP是您授权店铺的常用环境')
                    return
                }

                if(this.title=='添加店铺'){
                    if(this.platform==1){
                        toAccredit({
                            marketPlaceIds:this.addForm.marketPlaceIds,
                            marketId:this.addForm.marketId,
                            shopName:this.addForm.shopName,
                            tips:this.addForm.tips,
                        }).then(res=>{
                            console.log(res);
                            if(res.data.code==200){
                                // window.open(res.data.data)
                                window.location.href=res.data.data
                                this.Close()
                                this.getListPage()
                            }else{
                                this.$message.info(res.data.message)
                            }
                        }).catch(()=>{
                            this.buttonLoading=false
                        })
                    }
                    if(this.platform==2){
                        this.buttonLoading=true
                        shopRakutenAdd({
                            marketPlaceIds:this.addForm.marketPlaceIds,
                            rakutenLicense:this.addForm.key,
                            rakutenSecret:this.addForm.value,
                            userId:this.$store.state.user.userId,
                            shopName:this.addForm.shopName,
                            tips:this.addForm.tips,
                        }).then(res=>{
                            this.buttonLoading=false
                            console.log(res);
                            if(res.data.code==200){
                                this.$message.success(res.data.message)
                                this.Close()
                            }else {
                                this.$message.error(res.data.message)
                            }
                        }).catch(()=>{
                            this.buttonLoading=false
                        })
                    }
                    if(this.platform==304){
                        this.buttonLoading=true
                        shopify({
                            marketplaceId:this.addForm.marketPlaceIds[0],
                            shopName:this.addForm.shopName,
                            shop:this.addForm.shop
                        }).then(res=>{
                            console.log(res);
                            this.buttonLoading=false
                            if(res.data.code==200){
                                window.open(res.data.data)
                            }else {
                                this.$message.error(res.data.message)
                            }
                        }).catch(()=>{
                            this.buttonLoading=false
                        })
                    }
                }
                if(this.title=="更新授权"||this.title=='店铺授权'){
                    if(this.platform==1){
                        this.buttonLoading=true
                        updateAuthorize({
                            id:this.id,
                            marketId:this.addForm.marketId,
                            marketPlaceIds:this.addForm.marketPlaceIds,
                            tips:this.addForm.tips
                        }).then(res=>{
                            this.buttonLoading=false
                            console.log(res);
                            if(res.data.code==200){
                                // window.open(res.data.data)
                                window.location.href=res.data.data
                                this.Close()
                            }else {
                                this.$message.info(res.data.message)
                                return
                            }
                        }).catch(()=>{
                            this.buttonLoading=false
                        })
                    }
                    if(this.platform==2){
                        this.buttonLoading=true
                        ShopRakutenUpdate({
                            id:this.id,
                            marketPlaceIds:this.addForm.marketPlaceIds,
                            rakutenLicense:this.addForm.key,
                            rakutenSecret:this.addForm.value,
                            userId:this.$store.state.user.userId,
                        }).then(res=>{
                            this.buttonLoading=false
                            if(res.data.code==200){
                                this.$message.success(res.data.message)
                                this.Close()
                            }else {
                                this.$message.error(res.data.message)
                            }
                        }).catch(()=>{
                            this.buttonLoading=false
                        })
                    }
                    if(this.platform==304){
                        this.buttonLoading=true
                        shopify({
                            marketPlaceId:this.addForm.marketPlaceIds,
                            shopName:this.addForm.shopName,
                            shop:this.addForm.shop
                        }).then(res=>{
                            console.log(res);
                            this.buttonLoading=false
                            if(res.data.code==200){
                                window.open(res.data.data)
                            }else {
                                this.$message.error(res.data.message)
                            }
                        }).catch(()=>{
                            this.buttonLoading=false
                        })
                    }
                }
                
            })
        },
        changeShopName(id,name){
            console.log(id);
            this.$prompt('请输入店铺名称', '修改店铺名称', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue:name
            }).then(({ value }) => {
                exitShopName({id:id,shopName:value}).then(res=>{
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.getListPage()
                    }else {
                        this.$message.info(res.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
        },
        addStoreOpen(title){
            this.dialogLoading=true
            this.title=title
            this.addStoredialog=true
            this.addShopInit()
        },
        setShowInit(id,a,b){
            this.title=a+'|'+b
            this.setShow=true
            this.id=id
            this.settingShow()
        },
        settingShow(){
            this.dialogLoading=true
            getMarketPlace(this.id).then(res=>{
                console.log(res.data.data.data);
                this.showTable=res.data.data.data
                this.dialogLoading=false
            })
        },
        getListPage(){
            this.loading=true
            listPage(this.pageInfo).then(res=>{
                console.log(res.data.values);
                this.total=res.data.pageInfo.total
                this.tableDate=res.data.values
                this.loading=false
            })
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getListPage()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getListPage()
        },
        addShopInit(){
            addStoreInit().then(res=>{
                console.log(res);
                this.marketList=res.data.data.authorizeMarketTree
                this.marketList.forEach(item=>{
                    if(item.name=='北美'){
                        item.amazonMarketPlaceResList.forEach(i=>{
                            this.camas.push({id:i.id,name:i.name,marketId:i.marketId})
                        })
                    }
                    if(item.name=='欧洲'){
                        item.amazonMarketPlaceResList.forEach(i=>{
                            this.europee.push({id:i.id,name:i.name,marketId:i.marketId})
                        })
                    }
                    if(item.name=='远东'){
                        item.amazonMarketPlaceResList.forEach(i=>{
                            this.East.push({id:i.id,name:i.name,marketId:i.marketId})
                        })
                    }
                })
                console.log(this.camas,this.europee,this.East);
                this.dialogLoading=false
            })
        },
        Close(){
            this.sure=false
            this.reset()
            this.camas=[]
            this.East=[]
            this.europee=[]
            this.addStoredialog=false
            console.log(1);
        },
        deletEles(){
           this.addForm.marketPlaceIds=[]
        },
        find(){
            this.pageInfo.current=1
            this.getListPage()
        },
        defaultShow(id){
            this.dialogLoading=true
            defaultShow(id).then(res=>{
                if(res.data.code==200){
                    this.$message.success(res.data.data)
                    this.settingShow()
                    this.dialogLoading=false
                }else {
                    this.$message.info(res.data.message)
                    this.settingShow()
                    this.dialogLoading=false
                }
            })
        },
        changeStatus(id){
            this.dialogLoading=true
            changeStatus(id).then(res=>{
                if(res.data.code==200){
                    this.settingShow()
                    this.dialogLoading=false
                    this.$message.success(res.data.data)
                }else {
                    this.settingShow()
                    this.$message.info(res.data.message)
                    this.dialogLoading=false
                }
            })
        },
    }
}
</script>

<style scoped lang='scss'>
.site{
    display: flex;
    >span{
        display: block;
        // width: 100px;
        margin-right: 20px;
    }
    >div{
        width:500px;
    }
}
</style>